canopy.controller("AssetDetailController", function ($scope, $state, $window, $stateParams, assets, orders, basket, shares, $q, canopyModals, canopySession, canopyEvents, utilities, tasks, processes, fusion, listService) {
	$scope.init = function () {
		$scope.page = {
			errors: [],
			agreedUsageTerms: false
		};
		
		var site = canopySession.getActiveSite();
		
		try {
			$scope.page.include = site.metadata.includes["asset-details"];
		}
		catch (e) {
			$scope.page.include = null;
		}
		
		$scope.assetType = $stateParams.assetType || "Resource";
		$scope.assetID   = $stateParams.assetID;

		if ($scope.assetID === undefined) {
			return;
		}

		$scope.initAsset();
	};
	
	var onLoad = function () {
		$scope.initActions();
		$scope.asset.fallbackImage = Luma.paths.context + "/system/mantle/marquee/site/static-images/fallback.png";
			
		if ($scope.asset.info && $scope.asset.info.engine === "Fusion") {
			$scope.initAssetRelatives();
			$scope.initFusionAssets();
		}
		
		if ($scope.asset.transforms) {
			var transformFilter = function (element) {
				return assets.utilities.isTransformValid($scope.asset, element);
			};
		
			if($scope.asset.transforms.orderer) {
				$scope.asset.transforms.orderer = $scope.asset.transforms.orderer.filter(transformFilter) ;
			}

			if($scope.asset.transforms.downloader) {
				$scope.asset.transforms.downloader = $scope.asset.transforms.downloader.filter(transformFilter) ;
			}
		}
	};

	var onError = function (response) {
		$scope.page.errors.push(response.data);
	};
	
	var isRestricted = function (template) {	
		var user = canopySession.getUser();
		var available = template.asset.available;
		var state     = template.asset.state;
		var deleted = template.asset.deleted;

		if (deleted || !available || state == "Expired" || state == "Quarantined" || !template.current) {
			return true;	
		}
		
		return false;
	};

	$scope.initAsset = function () {
		var onSuccess = function (response) {
	
			if ($scope.assetType === "adaptation") {
				$scope.amendment = response.data.amendment;
				$scope.asset   = response.data.asset;
				$scope.assetID = response.data.template.asset.id;

				$scope.asset.template = response.data.template;
				
				var restricted = isRestricted($scope.asset.template);
				
				var promises = [
					$scope.initTasks(),
					$scope.initAssetInfo(),
					$scope.initMedia()
				];
				
				if (restricted) {
					$scope.asset.restricted = true;
					$scope.initActions();
				}
				else {
					promises.push(
						$scope.initAssetRates(),
						$scope.initAssetTaxonomy(),
						$scope.initAssetTransforms(),
						$scope.initAssetDerivatives()
					);
				}
				
				$q.all(promises).then(function (response) {
					onLoad();
				});
			}
			else {
				$scope.asset = response.data;
				
				var promises = [
					$scope.initAssetInfo(),
					$scope.initAssetFields(),
					$scope.initAssetDescription(),
					$scope.initAssetUsage(),
					$scope.initAssetAvailability(),
					$scope.initAssetTaxonomy(),
					$scope.initAssetTransforms(),
					$scope.initAssetDerivatives(),
					$scope.initAssetRates()
				];
				
				$q.all(promises).then(function (response) {
					onLoad();
				});
			}
		};
		
		if ($scope.assetType === "adaptation") {
			assets.getAdaptation($scope.assetID).then(onSuccess).catch(onError);
		}
		else {
			assets.getAsset($scope.assetID).then(onSuccess).catch(onError);
		}
	};
	
	$scope.initFusionAssets = function () {
		$scope.asset.fusion = { };
		
		var list = listService.instantiate("ListUserAssets");
		
		list.setParameter("listing-sort", "asset-modified");
		list.setParameter("listing-order", "Descending");
		
		list.registerFilter({
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: [{
				flags: ["Metadata"],
				name: "com.intrepia.canopy.FusionTemplateUUID",
				value: $scope.asset.uuid
			}]
		});
		
		list.load().then(function (response) {
			$scope.asset.fusion.sources = response.data;
		});
	};
	
	$scope.onFusionSourceUpdate = function (asset) {		
		var onComplete = function (response) {
			canopyEvents.dispatch("genericEvent", {
				message: "A new version of " + asset.name + " has been uploaded."
			});
						
			$scope.initFusionAssets();
		};

		assets.utilities.updateAsset(asset, onComplete);
	};
	
	$scope.onFusionSourceSubmit = function (asset) {		
		var onComplete = function (batchID) {
			var modal = canopyModals.definitions.message({
				header: "Success",
				body: "Your batch was successfully submitted."
			});
			
			canopyModals.instantiate(modal).result.then(function () {			
				$state.go("user_batch", {
					batchID: batchID
				});
			});
		};
		
		fusion.utilities.submitBatch($scope.asset.domainID, $scope.asset.info.templateID, asset.id, onComplete);
	};
	
	$scope.initAssetInfo = function () {
		var onSuccess = function (response) {
			$scope.asset.info = response.data;
		};

		return assets.getAssetInfo($scope.assetID, $scope.asset.type).then(onSuccess).catch(onError);
	};
	
	$scope.initAssetFields = function () {
		var onSuccess = function (response) {
			$scope.asset.fields = response.data.filter(function (element) {
				return element.value;
			});
		};

		return assets.getAssetFields($scope.assetID).then(onSuccess).catch(onError);
	};

	$scope.initAssetDescription = function () {
		var onSuccess = function (response) {
			$scope.asset.description = response.data;
		};

		return assets.getAssetNarrative($scope.assetID, "description").then(onSuccess).catch(onError);
	};

	$scope.initAssetUsage = function () {
		var onSuccess = function (response) {
			$scope.asset.usage = response.data;
		};

		return assets.getAssetNarrative($scope.assetID, "usage").then(onSuccess).catch(onError);
	};

	$scope.initAssetAvailability = function () {
		var onSuccess = function (response) {
			$scope.asset.availability = response.data;
		};

		return assets.listAssetAvailability($scope.assetID).then(onSuccess).catch(onError);
	};

	$scope.initAssetTaxonomy = function () {
		var onSuccess = function (response) {
			$scope.asset.taxonomy = response.data;
		};

		return assets.getAssetTaxonomy($scope.assetID).then(onSuccess).catch(onError);
	};

	$scope.initAssetTransforms = function () {
		$scope.asset.transforms = {};
		
		var onSuccessOrderer = function (response) {
			$scope.asset.transforms.orderer = response.data;
		};
		
		var onSuccessDownloader = function (response) {
			$scope.asset.transforms.downloader = response.data;
		};

		return $q.all(
			assets.getAssetTransforms($scope.asset.id, "Orderer"   ).then(onSuccessOrderer).catch(onError),
			assets.getAssetTransforms($scope.asset.id, "Downloader").then(onSuccessDownloader).catch(onError)
		);
	};
	
	$scope.initAssetDerivatives = function () {
		var onSuccess = function (response) {
			$scope.asset.derivatives = response.data;
		};

		return assets.getAssetDerivatives($scope.assetID).then(onSuccess).catch(onError);
	};

	$scope.initAssetRates = function () {
		var onSuccess = function (response) {
			$scope.asset.rates = response.data;
		};

		return assets.getAssetRates($scope.assetID).then(onSuccess).catch(onError);
	};

	$scope.initAssetRelatives = function () {
		var onSuccess = function (response) {
			$scope.asset.relatives = response.data;
		};

		return assets.listAssetRelatives($scope.assetID).then(onSuccess).catch(onError);
	};
	
	$scope.initActions = function () {	
		var user = canopySession.getUser();
		$scope.actions = [{
			name: "add",
			label: "Add to...",
			actions: [{
				label: "Basket",
				execute: function () {
					basket.addAssets($scope.asset.id);
				},
				permitted: function () {
					return assets.utilities.isAssetSelectable($scope.asset) && user.hasService("application.basket") && !$scope.asset.restricted;
				}
			}, {
				label: "Share",
				execute: function () {
					shares.quickShareAssets($scope.asset.id);
				},
				permitted: function () {
					return $scope.asset.type == "Resource" && user.hasService("application.assets.share");
				}
			}]
		}, {
			name: "adaptation",
			label: "Adaptation...",
			actions: [{
				label: "Edit",
				href: Luma.paths.context + "/router/adaptation/" + $scope.asset.id,
				permitted: function () {
					return $scope.asset.type == "Adaptation" && $scope.asset.domainID == Luma.user.userID
						&& ($scope.asset.template.atom.major <= $scope.asset.template.asset.atom.major)
						&& ($scope.asset.template.atom.minor <= $scope.asset.template.asset.atom.minor)
						&& ($scope.asset.template.atom.micro <= $scope.asset.template.asset.atom.micro);
				},
				executeEditNewVersion: function () {
					var onConfirm = function () {
						var destination = Luma.paths.context + "/router/template/" + $scope.asset.template.currentID + "?adaptationID=" + $scope.asset.id;
						$window.location = destination;
					}

					var modal = canopyModals.definitions.message({
						header: "Important",
						body: "This artwork has been created from an old version of this template. If you click continue, you will be redirected to the newer version of the template. Please check your artwork carefully and save once you're finished.",
						confirm: "Continue",
						cancel: "Cancel"
					});
					
					canopyModals.instantiate(modal).result.then(onConfirm);
				}
			}, {
				label: "Delete",
				execute: function () {
					var modal = canopyModals.definitions.message({
						header: "Delete adaptation",
						body: "Are you sure you want to delete this adaptation? You cannot undo this action.",
						confirm: "Delete",
						cancel: "Cancel"
					});
				
					canopyModals.instantiate(modal).result.then(function () {
						var onSuccess = function () {
							$state.go("user_adaptation", {
								adaptationID: null
							});
						};
					
						assets.deleteAsset($scope.asset.id).then(onSuccess);
					});
				},
				permitted: function () {
					return $scope.asset.type == "Adaptation" && $scope.asset.domainID == Luma.user.userID;
				}
			}]
		}, {
			name: "template",
			label: "Template...",
			actions: [{
				label: "Edit",
				href: Luma.paths.context + "/router/template/" + $scope.asset.atomID,
				permitted: function () {
					return ($scope.asset.type == "Template" || $scope.asset.type == "Profile") && $scope.asset.info.engine != "Fusion";
				}
			}, {
				label: "Upload data source",
				execute: function () {
					var onComplete = function (response) {
						canopyEvents.dispatch("genericEvent", {
							message: "Data source successfully submitted."
						});
						
						$scope.initFusionAssets();
					};
					
					fusion.utilities.addDataSource($scope.asset, onComplete);
				},
				permitted: function () {
					return ($scope.asset.type == "Template" || $scope.asset.type == "Profile") && $scope.asset.info.engine == "Fusion";
				}
			}, {
				label: "Download proof",
				href: Luma.paths.context + "/servlet/interface/com.intrepia.entropy.DownloadTemplateProof?templateID=" + $scope.asset.atomID,
				permitted: function () {
					return ($scope.asset.type == "Template" || $scope.asset.type == "Profile") && user.hasService("application.templates.download_proof");
				}
			}]
		}];
		
		$scope.actionCount = 0;
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
			
			$scope.actionCount += category.actions.length;
		});
		
		$scope.onKeywordClick = function () {
			var modal = canopyModals.definitions.message({
				header: "Nope",
				body: "This doesn't work yet.",
				confirm: "OK then"
			});
		
			canopyModals.instantiate(modal);
		};
		
		var onSuccess = function (response) {
			$scope.asset.actions = response.data;
		};
		
		return assets.getAssetActions($scope.asset.id, "Orderer").then(onSuccess);
	};
	
	$scope.initAdaptationData = function () {
		var onSuccess = function (response) {
			for (var property in response) {
				if ($scope.asset.hasOwnProperty(property) || property === "asset") {
					continue;
				}

				$scope.asset[property] = response.data[property];
			}
		};

		return assets.getAdaptation($scope.assetID).then(onSuccess).catch(onError);
	};
	
	$scope.initTasks = function () {
		var onSuccess = function (response) {
			$scope.asset.tasks = response.data;
			$scope.asset.tasksComplete = true;
			
			for (var i = 0; i != $scope.asset.tasks.length; ++i) {
				if ($scope.asset.tasks[i].completed === undefined) {
					$scope.asset.tasksComplete = false;
					break;
				}
			}
		};
		
		var onError = function (response) {
		};
		
		return assets.listAssetTasks($scope.asset.id).then(onSuccess).catch(onError);
	};
	
	$scope.initMedia = function () {
		var onSuccess = function (response) {
			$scope.asset.media = response.data;
		};
		
		var onError = function (response) {
		};
		
		return assets.getAdaptationMedia($scope.asset.id).then(onSuccess).catch(onError);
	};

	$scope.dump = function () {
		console.dir($scope.asset);
	};
	
	$scope.orderAsset = function (transform) {
		if (transform && $scope.asset.type !== "Adaptation") {
			var params = {
				assetID: $scope.asset.id,
				transformID: transform.id
			};
			
			$state.go("^.orderAsset", params);
		}
		
		if (transform && $scope.asset.type === "Adaptation") {
			var params = {
				assetID: $scope.asset.template.atom.assetID,
				transformID: transform.id,
				adaptationID: $scope.asset.id
			};

			$state.go("^.orderAdaptation", params);
		}				
	};
	
	$scope.doKeywordSearch = function (keyword) {
		var params = {
			keywordIDs: keyword.id
		};
		
		$state.go("^.list", params);
	};

	$scope.init();
});